<script>
	import { initialTab } from '../utilities/store';

	export let target;

  const slug = target.id.split('-')[1];
  const body = document.getElementsByTagName('body')[0];
  const closeBtn = target.querySelector('.close-btn');

  const modalOpen = (tabId) => {
		target.classList.add('show');
		body.children[0].classList.add('is-open-modal');
		$initialTab = tabId;
	}

	const modalClose = () => {
  	target.classList.remove('show');
		body.children[0].classList.remove('is-open-modal');
		history.replaceState(null, null, ' ');
	}

	const keydown = e => {
		e.stopPropagation()
		if (e.key === 'Escape') modalClose();
	}

	const modalOpenInit = () => {

		const tabSelector = '[href="#' + slug + '"]';
  	const tab0Selector = '[href="#' + slug + '/glauchau"]';
		const tab1Selector = '[href="#' + slug + '/lichtenstein"]';
		const tab2Selector = '[href="#' + slug + '/hohenstein"]';

		[...document.querySelectorAll(tabSelector)].forEach(item => item.addEventListener('click', () => modalOpen(0)));
		[...document.querySelectorAll(tab0Selector)].forEach(item => item.addEventListener('click', () => modalOpen(0)));
		[...document.querySelectorAll(tab1Selector)].forEach(item => item.addEventListener('click', () => modalOpen(1)));
		[...document.querySelectorAll(tab2Selector)].forEach(item => item.addEventListener('click', () => modalOpen(2)));
	}

	const modalCloseInit = () => {
		body.addEventListener('click', (e) => {
			if(e.target.classList.contains('modal')) modalClose();
		});
		closeBtn.addEventListener('click', () => modalClose());
		window.addEventListener('keydown', (e) => keydown(e));
	}

	modalOpenInit();
	modalCloseInit();

</script>


