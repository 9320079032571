<script>

  export let target;
  const mainNavigation = target.children[0];

	// Show Submenu
	const showSubmenu = (navItem) => {
		// close all submenus immediatly before opening a new one
		[...mainNavigation.getElementsByClassName('sub-menu')].forEach(item => item.classList.remove('show'));
		navItem.querySelector('.sub-menu').classList.add('show');
	}

	// Hide Submenu
	const hideSubmenu = (navItem) => {
		navItem.querySelector('.sub-menu').classList.remove('show');
	}

	// Manage Focus Behavior for Submenu
	const handleFocus = (navItem) => {
		// show submenu on focus of menu item
		const submenuItems = navItem.querySelector('.sub-menu').children;
		navItem.children[0].addEventListener('focus', e => { showSubmenu(e.target.parentElement) });
		// hide submenu on removing focus of the last submenu item
		[...submenuItems].pop().children[0].addEventListener('blur', e => {
			hideSubmenu(e.target.parentElement.parentElement.parentElement)
		});
	}

	// Show/Hide Desktop Sub Menu
	const handleSubmenu = () => {

		let navItems = mainNavigation.children;
		let subMenus = [...mainNavigation.getElementsByClassName('sub-menu')];

		[...navItems].forEach(navItem => {
			if (navItem.classList.contains('menu-item-has-children')) {

				// Show Submenu On Mouseenter
				navItem.addEventListener('mouseenter', e => {
					showSubmenu(e.currentTarget);
				});

				// Hide Submenu On Mouseleave With Timeout
				navItem.addEventListener('mouseleave', e => {
					setTimeout(() => hideSubmenu(e.target), 50);
				});

				// Close All Submenus On Scroll
				document.addEventListener('scroll', () => subMenus.forEach(item => item.classList.remove('show')));

				// Process Focus Behavior
				handleFocus(navItem);
			}
		});
	}

	handleSubmenu();
</script>
