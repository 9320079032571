<script>
	import { initialTab } from '../utilities/store';

  export let target;

  let tabs = false;
  const navLinks = target.querySelectorAll('.wp-block-getwid-tabs__nav-link');
	const navContents = target.querySelectorAll('.wp-block-getwid-tabs__tab-content');

	let activeTabValue;
	$: activeTabValue = $initialTab;

	const handleClick = tabValue => () => activeTabValue = tabValue;

	// exclude hero swiper due to the use of same WP Block "Tabs"
  if (!target.classList.contains('hero-swiper')) {
		tabs = true;
		Array.from(target.children).forEach(node => node.remove());
	}

</script>

{#if tabs}
  <ul class="tab-links">
    {#each navLinks as navLink, index}
      <li class={activeTabValue === index ? 'active' : ''} on:click={handleClick(index)}>
        <span>{navLink.querySelector('.wp-block-getwid-tabs__title').innerText}</span>
      </li>
    {/each}
  </ul>

  {#each navContents as navContent, index}
    {#if activeTabValue === index}
      <div class="tab-content">
        {@html navContent.innerHTML}
      </div>
    {/if}
  {/each}
{/if}
