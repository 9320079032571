<script>
	import { mobileNavToggleState } from '../utilities/store';

	export let target = false;
  const navToggle = target.children[0];

	let mobileNavState = false;
	$: $mobileNavToggleState = mobileNavState;
</script>

<button class="icon icon-menu nav-toggle" tabindex="0" on:click={() => mobileNavState = !mobileNavState}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <title>Menü öffnen/schließen</title>
    <path d="M3 13h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 7h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 19h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1z"></path>
  </svg>
</button>
