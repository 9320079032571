<script lang="ts">
  import Tailwind from "./Tailwind.svelte";
  import GlobalCSS from "./GlobalCSS.svelte";
  import MasonryArchive from "./components/MasonryArchive.svelte";
  import HeroSwiper from "./components/HeroSwiper.svelte";
  import NavBar from "./components/NavBar.svelte";
  import SearchBar from "./components/SearchBar.svelte";
  import MainNavigation from "./components/MainNavigation.svelte";
  import NavToggle from "./components/NavToggle.svelte";
  import SearchToggle from "./components/SearchToggle.svelte";
  import MenuDrawer from "./components/MenuDrawer.svelte";
  import Modal from "./components/Modal.svelte";
  import SlideToggle from "./components/SlideToggle.svelte";
  import ContactForm from "./components/ContacForm.svelte";
  import Tabs from "./components/Tabs.svelte";

  // NavBar Component
  if (document.querySelector('#navBar')) {
    let navbar = document.getElementById('navBar');
    new NavBar({
      target: navbar,
      props: {
        target: navbar
      }
    });
  }

  // SearchBar Component
  if (document.querySelector('#searchBar')) {
    let searchBar = document.getElementById('searchBar');
    new SearchBar({
      target: searchBar,
      props: {
        target: searchBar
      }
    });
  }

  // NavToggle Component
  if (document.querySelector('#navToggle')) {
    let navToggle = document.getElementById('navToggle');
    new NavToggle({
      target: navToggle,
      props: {
        target: navToggle
      }
    });
  }

  // SearchToggle Component
  if (document.querySelector('#searchToggle')) {
    let searchToggle = document.getElementById('searchToggle');
    new SearchToggle({
      target: searchToggle,
      props: {
        target: searchToggle
      }
    });
  }

  // MainNavigation Component
  if (document.querySelector('#mainNavigation')) {
    let mainNavigation = document.getElementById('mainNavigation');
    new MainNavigation({
      target: mainNavigation,
      props: {
        target: mainNavigation
      }
    });
  }

  // Hero Swiper Component
  if (document.querySelector('.hero-swiper')) {
    let heroSwiper = document.querySelector('.hero-swiper');
    new HeroSwiper({
      target: heroSwiper,
      props: {
        target: heroSwiper,
        swiperId: 'heroSwp'
      }
    });
  }

  if (document.querySelector('.wpcf7')) {
    let contactForm = document.querySelector('.wpcf7');
    new ContactForm({
      target: contactForm,
      props: {
        target: contactForm,
      }
    });
  }

  // Modal Component
  Array.from(document.querySelectorAll('.modal')).forEach((node) => new Modal({
    target: node,
    props: {target: node}
  }));

  // Masonry Component
  Array.from(document.querySelectorAll('.msnry-grid')).forEach((node) => new MasonryArchive({
    target: node,
    props: {target: node}
  }));

  // Masonry Component
  Array.from(document.querySelectorAll('.slide-toggle')).forEach((node) => new SlideToggle({
    target: node,
    props: {target: node}
  }));

  // Tabs Component
  Array.from(document.querySelectorAll('.wp-block-getwid-tabs')).forEach((node) => new Tabs({
    target: node,
    props: {target: node}
  }));


</script>
<Tailwind/>
<GlobalCSS/>
<MenuDrawer/>
