<script>
	import { mobileNavToggleState, searchBarToggleState } from '../utilities/store';
	import MobileNavigation from "./MobileNavigation.svelte";
	import Drawer from 'svelte-drawer-component';

	export let target = false;
	const menuDrawer = target;

  let open;
  let size;
	let innerWidth = window.innerWidth;

	$: {
		if (innerWidth >= 1024) $mobileNavToggleState = false;
		if (innerWidth < 576) size = '100%';
		else size = '450px';
		open = $mobileNavToggleState;
	}

</script>
<svelte:window bind:innerWidth />

<Drawer {open} size={size} placement='right' on:clickAway={() => open = false}>
  <MobileNavigation/>
</Drawer>

<style lang="scss">
  :global {
    div.overlay { background: rgba(0, 0, 0, 0.5) !important; }
  }
</style>
