<script>
	import { slide } from 'svelte/transition';

	export let target;
	const title = target.querySelector('.faq-title h3');
	const content = target.querySelector('.faq-content');

	let open = false;
	const toggleSlide = () => open = !open;

	Array.from(target.children).forEach((node) => { node.remove() });
</script>

<div class="faq-item">
  <div class="faq-title" on:click={() => toggleSlide()}>
    <h3>{title.innerText}</h3>
    <span class:open={open}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <title>chevron-right</title>
        <path d="M12.943 24.943l8-8c0.521-0.521 0.521-1.365 0-1.885l-8-8c-0.521-0.521-1.365-0.521-1.885 0s-0.521 1.365 0 1.885l7.057 7.057-7.057 7.057c-0.521 0.521-0.521 1.365 0 1.885s1.365 0.521 1.885 0z"></path>
      </svg>
    </span>
  </div>
  {#if open}
  <div class="faq-content" transition:slide>
    <div class="faq-content__inner">
      {@html content.innerHTML}
    </div>
  </div>
  {/if}
</div>
