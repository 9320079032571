<script>
	import { slide } from 'svelte/transition';
	const mainNavigation = document.getElementById('mainNavigation').children[0];

	let innerWidth = window.innerWidth;
	let isOpen = [];

	const toggleSubmenu = (index) => isOpen[index] = !isOpen[index];

	$: {
		for( let i = 0; i < 10; i++) {
			isOpen[i] = false;
			if (innerWidth >= 1024) isOpen[i] = false;
		}
  }

</script>
<svelte:window bind:innerWidth />

<div class="mobile-navigation">
  <ul class="{mainNavigation.classList}">
    {#each [...mainNavigation.children] as item, i}
      <li class="{item.classList}">
        <span>
          <a href="{item.children[0].href}">{item.children[0].innerText}</a>
          {#if item.classList.contains('menu-item-has-children')}
          <button class="submenu-toggle" on:click={() => toggleSubmenu(i)} class:show={isOpen[i]}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <title>chevron-right</title>
            <path d="M12.943 24.943l8-8c0.521-0.521 0.521-1.365 0-1.885l-8-8c-0.521-0.521-1.365-0.521-1.885 0s-0.521 1.365 0 1.885l7.057 7.057-7.057 7.057c-0.521 0.521-0.521 1.365 0 1.885s1.365 0.521 1.885 0z"></path>
            </svg>
          </button>
          {/if}
        </span>
        {#if item.classList.contains('menu-item-has-children')}
          {#if isOpen[i]}
          <div class="submenu" transition:slide>
            <ul>
              {#each [...item.children[1].children] as subitem}
                <li class="{subitem.classList}">
                  <a href="{subitem.children[0].href}">{subitem.children[0].innerText}</a>
                </li>
              {/each}
            </ul>
          </div>
          {/if}
        {/if}
      </li>
    {/each}
  </ul>
</div>
