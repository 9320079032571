<script lang="ts">
	import { onMount } from 'svelte';
	import Masonry from 'masonry-layout';

	export let target;

	let msnry;
	onMount(() => {
		setTimeout(function(){
			msnry = new Masonry(target, {
				itemSelector: '.msnry-grid-item',
				columnWidth: '.msnry-grid-sizer',
				percentPosition: true
			});
		}, 10);
	});
</script>
