<script>
  export let target = false;
  const responseOutput = target.querySelector('.wpcf7-response-output');

	document.addEventListener('wpcf7invalid', () => responseOutput.classList.add('alert', 'alert-danger'), false);
	document.addEventListener('wpcf7spam', () => responseOutput.addClass('alert alert-warning'), false);
	document.addEventListener('wpcf7mailfailed', () => responseOutput.addClass('alert alert-warning'), false );
	document.addEventListener('wpcf7mailsent', () => responseOutput.addClass('alert alert-success'), false );

</script>
