<script>
	import { mobileNavToggleState } from '../utilities/store';

  export let target = false;
  const navbar = target;
  const topline = document.getElementsByClassName('site-header__topline')[0];

	let y = 0;
	let oldValue = 0;
	let newValue = 0;
	let headerHeight;
	let toplineHeight;
	let navbarHeight;

	$: {
    toplineHeight = topline.getBoundingClientRect().height;
    navbarHeight  = navbar.getBoundingClientRect().height;
    headerHeight = toplineHeight + navbarHeight;

    // Handle Y Scroll Direction Classes
    const handleScrollDirection = (y) => {
      if (y > headerHeight * 2 && !$mobileNavToggleState) {
        newValue = y;
        if (oldValue < newValue) {
          navbar.classList.add('down');
          navbar.classList.remove('up');
        }
        if (oldValue > newValue) {
          navbar.classList.add('up');
          navbar.classList.remove('down');
        }
        oldValue = newValue;
      }
    }

    // handle Sticky Header Classes
    const handleStickyClasses = (y) => {
      // Add Sticky Class
      if (y >= headerHeight || $mobileNavToggleState) {
        navbar.classList.add('is-sticky');
      }
      // Remove Sticky Class And Also Scroll Classes
      else if (y <= toplineHeight) {
        navbar.classList.remove('is-sticky');
        navbar.classList.remove('up');   // remove scroll class
        navbar.classList.remove('down'); // remove scroll class
      }

			if ($mobileNavToggleState) {
				navbar.classList.remove('up');   // remove scroll class
				navbar.classList.remove('down'); // remove scroll class
				navbar.classList.add('drawer');
			}
			else {
				navbar.classList.remove('drawer');
      }
    }

    handleStickyClasses(y);
    handleScrollDirection(y);
	}
</script>
<svelte:window bind:scrollY={y} />
