<script>
	import {onMount} from "svelte";
	import { Swiper, SwiperSlide } from 'swiper/svelte';
	import SwiperCore, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';

  import HeroSwiperItem from "./HeroSwiperItem.svelte";

	export let target = false;
	export let swiperId = false;

	SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

	let slides;

  // Getwid Block Tabs
	if (target.classList.contains('wp-block-getwid-tabs')) {
		slides = Array.from(target.querySelectorAll('.wp-block-cover'));
  }
	// Magazine
  else {
		slides = Array.from(target.querySelectorAll('.wp-block-cover.magazine'));
  }

	let loaded = false;
	let loop = slides.length > 1;
	let pagination = (slides.length > 1) ? { el: '.swiper-pagination-' + swiperId } : false;
	let navigation = (slides.length > 1) ? { prevEl: '.prev-' + swiperId, nextEl: '.next-' + swiperId } : false;

	onMount(() => { loaded = true; });
	Array.from(target.children).forEach((node) => { node.remove() });

</script>

<Swiper
  spaceBetween={0}
  slidesPerView={1}
  watchOverflow={true}
  speed={600}
  loop={loop}
  autoplay={{delay: 5000}}
  pagination={{clickable: false}}
  navigation={navigation}
  preventClicks={false}>
  {#each slides as slide}
    <SwiperSlide>
      <HeroSwiperItem {slide}/>
    </SwiperSlide>
  {/each}
</Swiper>

{#if slides.length > 1}
  <div class="swiper-prev prev-{swiperId}" role="button" aria-label="Previous Slide">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true" focusable="false">
      <path d="M20.943 23.057l-7.057-7.057 7.057-7.057c0.521-0.521 0.521-1.365 0-1.885s-1.365-0.521-1.885 0l-8 8c-0.521 0.521-0.521 1.365 0 1.885l8 8c0.521 0.521 1.365 0.521 1.885 0s0.521-1.365 0-1.885z"></path>
    </svg>
  </div>
  <div class="swiper-next next-{swiperId}" role="button" aria-label="Next Slide">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true" focusable="false">
      <path d="M12.943 24.943l8-8c0.521-0.521 0.521-1.365 0-1.885l-8-8c-0.521-0.521-1.365-0.521-1.885 0s-0.521 1.365 0 1.885l7.057 7.057-7.057 7.057c-0.521 0.521-0.521 1.365 0 1.885s1.365 0.521 1.885 0z"></path>
    </svg>
  </div>
  <div class="swiper-pagination swiper-pagination-{swiperId}"></div>
{/if}

